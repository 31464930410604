import { Container } from '@mantine/core';
import React from 'react';
import { Footer } from '../Footer/Footer';
import Head from '../Head/Head';
import { HeroText } from '../Hero/Hero';
import useStyles from './Home.styles';

export default function Home() {
  const { classes } = useStyles();

  return (
    <>
      <Head title="Getting started" />

      <div className={classes.wrapper}>
        <Container px={null} className={classes.container} size={1100}>
          <HeroText />
        </Container>
      </div>

      <Footer withNavbar />
    </>
  );
}
